import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './SecretBoom.css';
import phoneImg from './assets/phoneImage.png';
// import phoneImg from './phone.svg';
import Footer from "./Footer.js";
import { propTypes } from 'react-bootstrap/esm/Image';
import { logDOM } from '@testing-library/react';

export default function SeceretBoom(props) {
    const [arrCheckedCategories, setArrCheckedCategories] = useState([
        false, false, false, false, false, false, false, false,
    ]);
    const [name, setName] = useState("רשמו כאן את מספר הטלפון שלכם");
    const [number, setNumber] = useState("false");
    const addCategory = (index) => {
        let some = 0;
        arrCheckedCategories.forEach(element => {
            if (element)
                some++;
        });
        if (some < 3 || arrCheckedCategories[index]) {
            let arr = arrCheckedCategories;
            arr[index] = !arr[index];
            setArrCheckedCategories([...arr]);
        }
    }
    function toRemarctly(){
        const tags = [];
        console.log(arrCheckedCategories,"props.userprops.userprops.user");
        const nameTgs = ['בשמים','הלבשה והנעלה לאישה','חופשות ותירות','מוצרים לבית ולמטבח','חשמל ואלקטרוניקה','הלבשה והנעלה לגבר','צעצועים ואטרקציות לילדים','אוכל ומוצרים לחיות']
        for (let index = 0; index < arrCheckedCategories.length; index++) {
            if (arrCheckedCategories[index]){
                tags.push(nameTgs[index])
            }
            
        }

        console.log(tags,"TagsTags");
        if(name.includes(0)){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type' : 'application/json'},
                body: JSON.stringify({
                  "email" : props.user["data"]["user_email"],
                  "sms_phone_number" : name,
                  "sms_country_code" : "IL" ,
                  "tags" : tags,
                })
              };
              fetch('https://app.remarkety.com/api/v1/stores/E4lbD6Xn/contacts',requestOptions)
              .then(function (response ) {
                response.json().then((json) => {
                props.changePagehome("/home")
             })
            }).catch((error) => {
              console.log(error);
              alert("התרחשה שגיאה, נסה שנית מאוחר יותר");
            });
        }
        else{
            setNumber("true")
        }


    }
    return <>
    <div>
        <div className="gradientLayner" style={{display: 'flex', paddingTop: '5%',paddingBottom: '5%'}}>
        <button className="back" onClick={props.goBack}>
        חזרה
      </button>
            <div style={{flexDirection: "column", textAlign: "right",margin: "0 6vw"}}>
                <div className="d-flex  mt-5 pt-4 justify-content-start firstPartWords big">בום ביי דואגים לכם  </div>
                <div className="d-flex  mt-2 justify-content-start firstPartWords big">לבומים אישיים וסודיים  </div>
                <div className="d-flex  mt-2 justify-content-start firstPartWords big">!שאסור לפרסם באתר</div>
                <div className="d-flex  mt-4 justify-content-start firstPartWords small"> ספרו לנו אילו מוצרים מענינים אתכם</div>
                <div className="d-flex  mt-2 justify-content-start firstPartWords small">ואנחנו נדאג לאתר ולסגור עבורכם הצעות </div>
                <div className="d-flex  mt-2 justify-content-start firstPartWords small pb-5 mb-5"> .אטרקטיביות ולשלוח ישירות אליכם לנייד</div>
            </div>
            <div  className="col-sm-12 col-lg-6 d-flex justify-content-center mt-5">
                <img className='img-fluid shadow-4 phoneImg' alt="" src={phoneImg}></img>
            </div>
        </div>
        <div>
            <div className="d-flex  mt-4 justify-content-center textbig" style={{paddingTop:'2vw', color: "#1F2B3C", fontFamily: 'Assistant', fontStyle: 'normal', fontWeight: 700, lineHeight: '50px',textAlign: 'center', }}>?על אילו קטגוריות תרצו לקבל בום סודי  </div>
            <div className="d-flex textSmall justify-content-center downTitle" style={{
                fontFamily: 'Assistant',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '39px',
                textAlign: 'center',
            }}>שימו לב! ניתן לבחור עד 3 קטגוריות אז בחרו בחכמה </div> 
        </div>
        <div>
            <div class="flex-container">
                <button className={`${arrCheckedCategories[0] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2`} onClick={() => addCategory(0)} >בשמים</button>
                <button className={`${arrCheckedCategories[1] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 `} onClick={() => addCategory(1)}>הלבשה והנעלה לאישה</button>
                <button className={`${arrCheckedCategories[2] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen2`} onClick={() => addCategory(2)}>חופשות ותיירות</button>
                <button className={`${arrCheckedCategories[3] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen1`} onClick={() => addCategory(3)}>מוצרים לבית ולמטבח</button>
            </div>
            <div class="flex-container">
                <button className={`${arrCheckedCategories[4] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2`} onClick={() => addCategory(4)}  >חשמל ואלקטרוניקה</button>
                <button className={`${arrCheckedCategories[5] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2`} onClick={() => addCategory(5)} >הלבשה והנעלה לגבר</button>
                <button className={`${arrCheckedCategories[6] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen3`} onClick={() => addCategory(6)} >צעצועים ואטרקציות לילדים</button>
                <button className={`${arrCheckedCategories[7] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen4`} onClick={() => addCategory(7)} >אוכל ומוצרים לחיות</button>
            </div>
        </div>
        <div style={{background: '#F5F9FF', padding:'3%',marginTop:'4%'}}>
                  <h2 
                  style={{ fontFamily: 'Assistant',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '39px',
                    textAlign: 'center',
                    color: '#1F2B3C' }} 
                    className="d-flex mr-sm-5 mr-md-2 downTitleone justify-content-center">?לאיזה מספר טלפון לשלוח לכם הצעה חמה כשאנחנו מוצאים אותה</h2>
                    <div className="d-flex justify-content-center mt-4 downTitle">אל דאגה אנחנו נשלח לכם הודעה רק </div>
                    <div className="d-flex justify-content-center downTitle">כשנמצא בום אישי ואטרקטיבי במיוחד </div>
                    <div style={{
                        margin: "4vw",
                        /* width: 50%; */
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                      {number == "true"? <p style={{color:"red"}}>לא הוכנס מספר טלפון</p>: <p></p>}
                            <div className="col-sm-12  col-md-8 col-lg-4">
                                <input className='btnDownFirst' value={name}  onChange={(e) => setName(e.target.value)}/>
                                {/* <input style={{  }} className='btnDownFirst'>רשמו כאן את מספר הטלפון שלכם </input> */}
                            </div>

                            <div className="col-sm-12  col-md-8 col-lg-4">
                                <button style={{}} className='btnDownScound' onClick={
                                    props.user["ID"] !== undefined ? toRemarctly :()=> props.changePage("/sign_in") }>עדכנו אותי על בומים סודיים</button>
                            </div>
                    </div>
                    
        </div> 
        <Footer
      />
    </div>
        {/* <Container fluid>
            <Row className="gradientLayner" >
                <Col className="col-sm-12 col-lg-6">
                    <h1 className="d-flex  mt-5 pt-4 justify-content-start firstPartWords big">בום בי דואגים לכם  </h1>
                    <h1 className="d-flex  mt-2 justify-content-start firstPartWords big">לבומיים אישיים וסודים  </h1>
                    <h1 className="d-flex  mt-2 justify-content-start firstPartWords big"> שאסור לפרסם באתר!</h1>
                    <h4 className="d-flex  mt-4 justify-content-start firstPartWords"> ספרו לנו אילו מוצרים מענינים אתכם</h4>
                    <h4 className="d-flex  mt-2 justify-content-start firstPartWords">ואנחנו נדאג לאתר ולסגור עבורכם הצעות </h4>
                    <h4 className="d-flex  mt-2 justify-content-start firstPartWords pb-5 mb-5"> אטרקטביות ולשלוח ישירות אליכם לנייד.</h4>
                </Col>
                <Col  className="col-sm-12 col-lg-6 d-flex justify-content-center mt-5">
                    <img className='img-fluid shadow-4 phoneImg' alt="" src={phoneImg}></img>
                </Col>
            </Row>
            <Row className="mt-3">
                <h1 className="d-flex  mt-4 justify-content-center" style={{ color: "#1F2B3C", fontWeight: "700" }}>על אילו קטגוריות תרצו לקבל בום סודי?  </h1>
                <h2 className="d-flex justify-content-center downTitle">שימו לב! ניתן לבחור עד 3 קטגוריות אז בחרו בחכמה </h2>
            </Row>
            <Row className="d-flex justify-content-center mt-4" >
                <Col className="d-flex justify-content-center flex-wrap">
                    <button className={`${arrCheckedCategories[0] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2`} onClick={() => addCategory(0)} >בשמים</button>
                    <button className={`${arrCheckedCategories[1] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 `} onClick={() => addCategory(1)}>הלבשה והנעלה לאישה</button>
                    <button className={`${arrCheckedCategories[2] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen2`} onClick={() => addCategory(2)}>חופשות ותירות</button>
                    <button className={`${arrCheckedCategories[3] ? `btnCategoryChecked` : `btnCategory`} mr-2 ml-2 smallScreen1`} onClick={() => addCategory(3)}>מוצרים לבית ולמטבח</button>
                </Col>
            </Row>

            <Row className="pt-4 pb-4" style={{ backgroundColor: '#F5F9FF' }}>
                <Col className="justify-content-center mt-5 mb-5">

                </Col>
            </Row>
        </Container> */}
    </>;
}