//react hooks
import React, { useState, useEffect } from "react";

//assets
import img_a from "./product-imgs/product_a.svg";
import arrow_right from "./assets/arrow-right.svg";
import arrow_left from "./assets/arrow-left.svg";
import s_facebook from "./assets/s-facebook.svg";
import s_whatsapp from "./assets/s-whatsapp.svg";
import s_telegram from "./assets/s-telegram.svg";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

//componet
function ProdactCategoryn(props) {
  const currentUrl = window.location.hostname + "/" + props.productData["id"];
  const shareText = "הוזמנת לצפות בקופון מטורף, כנסו עכשיו!!" + currentUrl;

  //count produts in loop
  useEffect(() => {
    if (props.render === "loop") {
      props.countLoop();
    }
  });

  //set state
  const [copied, setCopied] = useState(1);
  const [lightbox, setLightbox] = useState(0);
  const [image, setImage] = useState();

  //returns the h/m/s of coupon time to end
  function secondsToHms(action) {
    let current = props.time;
    let d = props.productData["time_to_stop"] - current;
    var h = Math.floor(d / 3600) - 2;
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return action === "hours" ? h : action === "minutes" ? m : s;
  }

  let stock = props.productData["stock"];
  let max_h = props.width > 900 ? "55vh" : "20px";

    //adds view to coupon's iews count
  function setView() {
  fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/get_user_remarcety?email=" + 
            props.userData["data"]["user_email"],
            {
              method: "GET",
            }
          ).then((response) =>  response.json())
        .then((resData) => {
           var people = JSON.parse(resData);

          fetch(

          "https://manage.boombuy.co.il/wp-json/react/v1/react-cart?product_id=" +
        props.productData["id"] +
         "&id=" +
        people.customers[0].id +
        "&email="+
         props.userData["data"]["user_email"]+
         "&$category="+
         props.productData["type"][0]["name"]
          ).then((response) =>  response.json())
        .then((resData) => {

        }
        );
         })

    fetch(
      "https://manage.boombuy.co.il/wp-json/views/v1/set?product_id=" +
        props.productData["id"],
      {
        method: "GET",
      }
    );
    return;
  }
  //render
  console.log(props.render === "category","props.render");
  console.log(props.productData["url"],"props.productData");
  console.log(!props.userData["ID"],"props.productData");
  return (
         <div class="product active product-category">
           <div  style={{display:"flex", flexDirection:"column"}}>
            <div style={{display:"flex", flexDirection:"row", width: "100%"}}>
             <div style={{ top: stock ? "-1px" : "",}} className="timeoutsmall-c">
                {/* <span className="timeoutsmallSpan-c" >
                  <h2 className="timeoutsmall-number-c" style={{margin:"0px", color: "white"}}>{secondsToHms("seconds")}</h2>
                  <h5 className="timeoutsmall-name">שניות</h5>
                </span>
                <span className="timeoutsmallSpan-c">
                  <h2 className="timeoutsmall-number-c" style={{margin:"0px", color: "white"}}>{secondsToHms("minutes")}</h2>
                  <h5 className="timeoutsmall-name">דקות</h5>
                </span>
                <span className="timeoutsmallSpan-c">
                  <h2 className="timeoutsmall-number-c" style={{margin:"0px", color: "white"}}>{secondsToHms("hours")}</h2>
                  <h5 className="timeoutsmall-name">שעות</h5>
                </span> */}
              </div>
              <div class="brand_loop-c brandC" >
                  {props.productData["brnad_logo"] ? (
                  <img
                  className="the-loop brand_loop-c img brandC imag_logo_category"
                      alt="logo"
                      src={
                        props.productData["brnad_logo"]
                          ? props.productData["brnad_logo"]
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                  <h2 className="the-loop brand_loop-c h2 brandC">
                    {props.productData["brand"]
                      ? props.productData["brand"]["name"]
                      : ""}
                  </h2>
                </div>
             

            </div>
              <div style={{marginTop: "14px"}} >
                <img
                  onClick={() => {
                    setLightbox(1);
                    setImage(
                        props.productData["gallery"][0]
                    );
                  }}
                
                  className=" product-img-category"

                  src={
                      props.productData["gallery"][0]
                  }
                  />
              </div>
            </div>
           
            <div >
              <div className="text_titel_cate">
                
                <h2 class="product-title_category">
                  {props.productData["title"]}
                </h2>
                <p class="small"></p>
                <div class="cta priceCategory">
                              <div className="price">
                {props.productData["static_sale_price"] ? (
                  <h2>
                    <span>₪</span>
                    {props.productData["static_sale_price"]}
                  </h2>
                ) : props.productData["static_price"] ? (
                  <h2>
                    <span>₪</span>
                    {props.productData["static_price"]}
                  </h2>
                ) : (
                  ""
                )}
                {props.productData["static_price"] &&
                props.productData["static_sale_price"] ? (
                  <h4>
                    <span>₪</span>
                    {props.productData["static_price"]}
                  </h4>
                ) : (
                  ""
                )}
              </div>
                 
                </div>
              </div>
              <div class="cta-small-c"  style={{padding: "6px"}}>
                <a
                target={
                  props.render === "category" || !props.userData["ID"] ? "" : "blank"
                }
                href={
                  props.render === "category"
                    ? "#"
                    : !props.userData["ID"]
                    ? "#"
                    : props.productData["url"]
                }
              >
                <button
                  onClick={
                    props.render === "Category"
                      ? () => props.changePage_andMain()
                      : !props.userData["ID"]
                      ? () => props.changePage("sign_in")
                      : () => setView()
                  }
                >
                  {props.productData["id"] === 32396
                    ? "לקבלת המתנה לחץ כאן"
                    : props.userData["ID"]
                     ?"לקנייה" 
                    :  "התחברו לקבלת ההטבה" 
                    }
                </button>
              </a>
              </div>
              <div class="product-footer flex footer-border-top">
                 <div>
                   <h5>צפו במוצר:</h5>
                 </div>
                  <div className="icons">
                    <a
                      target="blank"
                      href={
                        "https://t.me/share/url?url=" +
                        currentUrl +
                        "&text=" +
                        shareText
                      }
                    >
                      <img class="category_style-icons-api" src={s_telegram} />
                    </a>
                    <a target="blank" href={"whatsapp://send?text=" + shareText}>
                      <img class="category_style-icons-api" src={s_whatsapp} />
                    </a>
                    <a
                      target="blank"
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl
                      }
                    >
                      <img class="category_style-icons-api" src={s_facebook} />
                    </a>
                  </div>
              </div>
            </div>

          </div>

         
  );
}

export default ProdactCategoryn;
