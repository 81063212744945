//react hooks
import React, { useState, useEffect } from "react";
import { Player } from 'video-react';

//assets
import img_a from "./product-imgs/product_a.svg";
import arrow_right from "./assets/arrow-right.svg";
import arrow_left from "./assets/arrow-left.svg";
import s_facebook from "./assets/s-facebook.svg";
import s_whatsapp from "./assets/s-whatsapp.svg";
import s_telegram from "./assets/s-telegram.svg";
import ScrollToBottom from 'react-scroll-to-bottom';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Group from "./assets/Group.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactGA from 'react-ga';
// ReactGA.initialize('UA-189028073-1');
//componet
function Product(props) {
  const currentUrl = window.location.hostname + "/" + props.productData["id"];
  const shareText = "הוזמנת לצפות בקופון מטורף, כנסו עכשיו!!" + currentUrl;

  //count produts in loop
  useEffect(() => {
    if (props.render === "loop") {
      props.countLoop();
    }
  });

  //set state
  const [copied, setCopied] = useState(1);
  const [lightbox, setLightbox] = useState(0);
  const [image, setImage] = useState();
  const [status, toggle] = useState(0);
  const [indexs, setIndexs] = useState(0);

  //returns the h/m/s of coupon time to end
  function secondsToHms(action) {
    let current = props.time;
    let d = props.productData["time_to_stop"] - current;
    var h = Math.floor(d / 3600) - 2;
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return action === "hours" ? h : action === "minutes" ? m : s;
  }
  let stock = props.productData["stock"];
  let max_h = props.width > 900 ? "55vh" : "20px";
  let object
  
  //adds view to coupon's iews count
  function setView() {
    ReactGA.initialize('UA-189028073-1');
    ReactGA.event({
      category: 'clicktobuy',
      action: 'to buy'
    });
  fetch(

      "https://manage.boombuy.co.il/wp-json/react/v1/get_user_remarcety?email=" + 
            props.user["data"]["user_email"],
            {
              method: "GET",
            }
          ).then((response) =>  response.json())
        .then((resData) => {
           var people = JSON.parse(resData);

          fetch(

          "https://manage.boombuy.co.il/wp-json/react/v1/react-cart?product_id=" +
        props.productData["id"] +
         "&id=" +
        people.customers[0].id +
        "&email="+
         props.user["data"]["user_email"]+
         "&$category="+
         props.productData["type"][0]["name"]
          ).then((response) =>  response.json())
        .then((resData) => {
        }
        );
        })

    fetch(
      "https://manage.boombuy.co.il/wp-json/views/v1/set?product_id=" +
        props.productData["id"],
      {
        method: "GET",
      }
    )
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/remarkeprodact?email=" +
      props.user["data"]["user_email"] +
       "&product=peoduct-" + props.productData["id"],
       
    )
      .then((response) => response.json())
      .then((resData) => {
      console.log("yyyyyyyyyy");

      });
    return;
  }
 {
    props.playvideo === 'true'?
    (object=[{key:0 ,titel:"תיאור מוצר", text:""} ,{key:1, titel:`${props.acordiontitel}`,text:""}])
    : (object=[{key:0 ,titel:"תיאור מוצר", text:""}])
    
   
  }
    var questions = Object.keys(object).map((key) => [Number(key), object[key]]);
  //render
  return (
    <div
      className="product active"
      style={{ border: stock === 0 && props.render !== "loop" ? "none" : "" }}
    >
    <div className={props.render !== "loop" ? "header-prodact" : "header-prodact-small"} >
     {secondsToHms("seconds") < 0 ||
          secondsToHms("minutes") < 0 ||
          secondsToHms("hours") < 0 ? (
            ""
          ) : (
            <div style={{ top: stock ? "-1px" : "" }}className={props.render !== "loop"?"timeout": "timeoutsmall" } >
              <span className={props.render !== "loop"? "":"timeoutsmallSpan"}>
                <h2 className={props.render !== "loop"?"": "timeoutsmall-number" }>{secondsToHms("seconds")}</h2>
                <h5 className={props.render !== "loop"?"": "timeoutsmall-name" }>שניות</h5>
              </span>
              <span  className={props.render !== "loop"? "":"timeoutsmallSpan"}>
                <h2 className={props.render !== "loop"?"": "timeoutsmall-number" }>{secondsToHms("minutes")}</h2>
                <h5 className={props.render !== "loop"?"": "timeoutsmall-name" }>דקות</h5>
              </span>
              <span  className={props.render !== "loop"? "":"timeoutsmallSpan"}>
                <h2 className={props.render !== "loop"?"": "timeoutsmall-number" }>{secondsToHms("hours")}</h2>
                <h5 className={props.render !== "loop"?"": "timeoutsmall-name" }>שעות</h5>
              </span>
            </div>
          )}

            <div className={props.render !== "loop"? "brand" : "brand-small"}>
              {props.productData["brnad_logo"] ? (
                <img
                className="the-loop  brand_loop img "
                  alt="logo"
                  src={
                    props.productData["brnad_logo"]
                      ? props.productData["brnad_logo"]
                      : ""
                  }
                />
              ) : (
                ""
              )}
              <h2 className={props.render !== "loop"?"the-loop brand_loop h2": "name-baner-small" }>
                {/* {props.productData["brand"]
                  ? props.productData["brand"]["name"]
                  : ""} */}
              </h2>
            </div>
    </div>
    <div  className={props.render !== "loop"? "row flex mobile-home-app" : ""}   >      
    {props.render === "loop" || props.render === "category" || !lightbox ? (
        ""
      ) : (
        <Lightbox onClose={() => setLightbox(0)} image={image}></Lightbox>
      )}
      {props.render !== "loop"   && stock === 0 ? (
        <div className="overley">
          <div className="inner-overlay">
            <h2>מצטערים, הבום אזל מהמלאי</h2>
            <p>
              {!props.user["ID"]
                ? "על מנת לא לפספס את הבום שלכם בפעם הבאה הצטרפו למועדון הלקוחות ותקבלו התראות על בומים חמים"
                : "רוצים לקבל כל בוקר עדכון על הבום היומי בוואטסאפ?"}
            </p>
            <a
              onClick={() =>
                !props.user["ID"]
                  ? props.changePage("sign_in")
                  : window.location.replace(
                      "https://chat.whatsapp.com/KkjLWpGdcSkGAXtTDMtgbd"
                    )
              }
            >
              {!props.user["ID"]
                ? "הירשמו לBoomBuy"
                : "הצטרפו לקבוצת הווטצסאפ של בום ביי ולא תפספסו יותר אף דיל!"}
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
      {!props.productData["id"] ? (
        ""
      ) : (
        <div
          className={props.render !== "loop"?"product-details " : "product-details"}
          style={{
            maxHeight: stock === 0 && props.render !== "loop" ? max_h : "",
          }}
        >
          <div className="product-most-details">
          <div className={props.render !== "loop"?"matefet":""}>
          <div className={props.render !== "loop"?" product-details-carodel" : ""}>
          
             
              <div className="brandBuy">
              {props.productData["brnad_logo"] ? (
                <div></div>
                // <img
                //   alt="logo"
                //   src={
                //     props.productData["brnad_logo"]
                //       ? props.productData["brnad_logo"]
                //       : ""
                //   }
                // />
              ) : (
                ""
              )}
              <h2>
                {props.productData["brand"]
                  ? props.productData["shopkeeper"]
                  : ""}
              </h2>
            </div>
        {props.render !== "loop"?
          <div className=" mobile-image-continer">
          <div className="img-container">
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
              <img
                className="right-arrow arrow"
                onContextMenu={(e) => e.preventDefault()}
                src={arrow_right}
                onClick={() =>
                  props.img > 0
                    ? props.setImg(props.img - 1)
                    : props.setImg(props.productData["gallery"].length)
                }
              />
            )}
            <img
              onClick={() => {
                setLightbox(1);
                setImage(
                  props.img !== 0 && props.productData["gallery"]
                    ? props.productData["gallery"][props.img - 1]
                    : props.productData["main_image"]
                );
              }}
              className={ props.render !== "loop"?"product-img-loop":'product-img'}
              src={
                props.render !== "loop" &&
                props.img !== 0 &&
                props.productData["gallery"]
                  ? props.productData["gallery"][props.img - 1]
                  : props.productData["main_image"]
              }
            />
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
              <img
                onContextMenu={(e) => e.preventDefault()}
                className="left-arrow arrow"
                src={arrow_left}
                onClick={() =>
                  props.img < props.productData["gallery"].length
                    ? props.setImg(props.img + 1)
                    : props.setImg(0)
                }
              />
            )}
            </div>
           {props.render === "loop" || !props.productData["gallery"].length ? (
            ""
          ) : (
            <div className="dots">
              <div
                onClick={() => props.setImg(0)}
                className={props.img === 0 ? "active" : ""}
              />
              {props.productData["gallery"].map((single, index) => (
                <div
                  onClick={() => props.setImg(index + 1)}
                  className={props.img === index + 1 ? "active" : ""}
                  key={index}
                />
              ))}
            </div>
          )}

          </div>
          :<div></div>}
           {props.render === "loop"?         
          <div className="img-container">
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
              <img
                className="right-arrow arrow"
                onContextMenu={(e) => e.preventDefault()}
                src={arrow_right}
                onClick={() =>
                  props.img > 0
                    ? props.setImg(props.img - 1)
                    : props.setImg(props.productData["gallery"].length)
                }
              />
            )}
            <img
              onClick={() => {
                setLightbox(1);
                setImage(
                  props.img !== 0 && props.productData["gallery"]
                    ? props.productData["gallery"][props.img - 1]
                    : props.productData["main_image"]
                );
              }}
             className={ props.render !== "loop"?"product-img-loop":'product-img'}
              src={
                props.render !== "loop" &&
                props.img !== 0 &&
                props.productData["gallery"]
                  ? props.productData["gallery"][props.img - 1]
                  : props.productData["main_image"]
              }
            />
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
              <img
                onContextMenu={(e) => e.preventDefault()}
                className="left-arrow arrow"
                src={arrow_left}
                onClick={() =>
                  props.img < props.productData["gallery"].length
                    ? props.setImg(props.img + 1)
                    : props.setImg(0)
                }
              />
            )}
          </div>
          :<div></div>

          }
            <h2 className="product-title">{props.productData["title"]}</h2>
              <div className="price">
                {props.productData["static_sale_price"] ? (
                  <h2>
                    <span>₪</span>
                    {props.productData["static_sale_price"]}
                  </h2>
                ) : props.productData["static_price"] ? (
                  <h2>
                    <span>₪</span>
                    {props.productData["static_price"]}
                  </h2>
                ) : (
                  ""
                )}
                {props.productData["static_price"] &&
                props.productData["static_sale_price"] ? (
                  <h4>
                    <span>₪</span>
                    {props.productData["static_price"]}
                  </h4>
                ) : (
                  ""
                )}
              </div>

            {props.render === "loop" ? (
              ""
            ) : props.productData["title"] ? (
            questions.map((single, indexs) => (
              <div>
                <div
                className="itemAcordion"
                style={{ maxHeight: status === indexs ? "2000px" : "60px" }}
                key={indexs}
              >
                <span
                  onClick={() => (status !== indexs ? toggle(indexs) : toggle(""))}
                  className="acordionHome"
                >

                  {status === indexs ?
                  ""
                  : questions[indexs][1].titel
                  }
                 <i
                    style={{
                      transform:
                        status !== indexs ? "rotate(0deg)" : "rotate(45deg)",
                    }}
                    className={indexs == 0? "show-more fa fa-plus mobil-none-icon" :"show-more fa fa-plus" } 
                  ></i>{" "}
                </span>
                {status === indexs || 1 === 1 ? (
                  <span
                    className="acordionAnswer"
                    // dangerouslySetInnerHTML={{ __html: single[1].answer }}
                  >
                {questions[indexs][1].key == 1?
                 <iframe width="100%" height="300px" src={props.acoriontex} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  : <p
                    dangerouslySetInnerHTML={{
                      __html: props.productData["short_description"].replace(
                        /\n\r/g,
                        "<br>"
                      ),
                    }}
                  ></p>
                }
                  </span>
                ) : (
                  ""
                )}
                </div>
              
              </div> 
            ))
            ) : (
              ""
            )}
          

      </div>
      </div>
            <div className={ props.render === "loop"? "cta-small" :"cta"}>
              {stock === 0 ? (
                <h5>המלאי אזל</h5>
              ) : stock > 0 ? (
                <h5>
                  מלאי מוגבל ל: <span>{stock} יחידות בלבד</span>
                </h5>
              ) : (
                ""
              )}
              {!props.productData["coupon-code"] ||
              props.render === "loop" ||
              !props.user["ID"] ? (
                ""
              ) : (
                <div className="code">
                  <h5>העתיקו קוד קופון לקבלת ההטבה</h5>
                  <span>
                    <h5>{props.productData["coupon-code"]}</h5>
                    <a
                      onClick={() => {
                        {
                          setCopied(0);
                          navigator.clipboard.writeText(
                            props.productData["coupon-code"]
                          );
                        }
                      }}
                    >
                      {copied > 0 ? "העתיקו" : "הועתק"}
                    </a>
                  </span>
                </div>
              )}
              <a
                target={
                  props.render === "loop" || !props.user["ID"] ? "" : "blank"
                }
                href={
                  props.render === "loop"
                    ? "#"
                    : !props.user["ID"]
                    ? "#"
                    : props.productData["url"]
                }
              >
                <button
                  onClick={
                    props.render == "loop"
                      ? () => props.changePage_andMain()
                      : !props.user["ID"]
                      ? () => props.changePage("sign_in")
                      : () => setView()
                  }
                >
                  {props.productData["id"] === 32396
                    ? "לקבלת המתנה לחץ כאן"
                    : props.user["ID"]
                     ?props.productData["prod_botten_login"] == ""? "לקנייה" :props.productData["prod_botten_login"]
                        : props.productData["prod_botten_nologin"] == ""? "התחברו לקבלת ההטבה" : props.productData["prod_botten_nologin"]
                    }
                </button>
              </a>
            </div>
          </div>

        </div>
      )}
      {!props.productData["id"] ? (
        ""
      ) : (
        props.render !== "loop"?
        <div className="product-gallery flex galery-app-top" style={{flexDirection: "column"}}>
         
          <div className="img-container galery-app">
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
                 <LazyLoadImage
                    className="right-arrow arrow"
                    onContextMenu={(e) => e.preventDefault()}
                    src={arrow_right}
                    onClick={() =>
                    props.img > 0
                        ? props.setImg(props.img - 1)
                        : props.setImg(props.productData["gallery"].length)
                   }
                />
              // <img
              //   className="right-arrow arrow"
              //   onContextMenu={(e) => e.preventDefault()}
              //   src={arrow_right}
              //   onClick={() =>
              //     props.img > 0
              //       ? props.setImg(props.img - 1)
              //       : props.setImg(props.productData["gallery"].length)
              //   }
              // />
            )}
            
            <img
              onClick={() => {
                setLightbox(1);
                setImage(
                  props.img !== 0 && props.productData["gallery"]
                    ? props.productData["gallery"][props.img - 1]
                    : props.productData["main_image"]
                );
              }}
              className={ props.render !== "loop"?"product-img-loop":'product-img'}
              src={
                props.render !== "loop" &&
                props.img !== 0 &&
                props.productData["gallery"]
                  ? props.productData["gallery"][props.img - 1]
                  : props.productData["main_image"]
              }
            />
            {props.render === "loop" || !props.productData["gallery"].length ? (
              ""
            ) : (
              <img
                onContextMenu={(e) => e.preventDefault()}
                className="left-arrow arrow"
                src={arrow_left}
                onClick={() =>
                  props.img < props.productData["gallery"].length
                    ? props.setImg(props.img + 1)
                    : props.setImg(0)
                }
              />
            )}
          </div>
          {props.render === "loop" || !props.productData["gallery"].length ? (
            ""
          ) : (
            <div className="dots">
              <div
                onClick={() => props.setImg(0)}
                className={props.img === 0 ? "active" : ""}
              />
              {props.productData["gallery"].map((single, index) => (
                <div
                  onClick={() => props.setImg(index + 1)}
                  className={props.img === index + 1 ? "active" : ""}
                  key={index}
                />
              ))}
            </div>
          )}
          {props.render === "loop" ?(""):(
            <div>
              <a href={`${props.banertopurl}`} target="_blank">
              <img
                className="galery-app"
                style={{width: "80%"}}
                  src={props.banertop}
                />
          </a>
            
            </div>
          // <div className="etchivot">
          // <div>
          //   <p style={{fontSize:"1.5476190476190477vw"}}>מתחייבים למחיר הזול בישראל!</p>  
          //   <p style={{fontSize:"1.3095238095238095vw"}}>מצאתם מחיר זול יותר?  <a>צרו איתנו קשר</a></p>  
          // </div>
          //   <img
          //     src={Group}
          //   />
          // </div>
          )
          }

        </div>
        :<div></div>
      )}
                {props.render === "loop" ?(""):(
            <div className="baner-top-mobile-div">
              <a href={`${props.banertopurl}`} target="_blank">
                <img
                  className="galery-app baner-top-mobile"
                  style={{}}
                    src={props.banertop}
                  />
          </a>
            
            </div>
          // <div className="etchivot">
          // <div>
          //   <p style={{fontSize:"1.5476190476190477vw"}}>מתחייבים למחיר הזול בישראל!</p>  
          //   <p style={{fontSize:"1.3095238095238095vw"}}>מצאתם מחיר זול יותר?  <a>צרו איתנו קשר</a></p>  
          // </div>
          //   <img
          //     src={Group}
          //   />
          // </div>
          )
          }
      </div>

        <div className={props.render !== "loop"? "product-footer foter-loop-product" : "product-footer"}>
              <p className={props.render !== "loop"? "small" :"small-mobile"}>
              {props.render === "loop"
                ? ""
                : props.productData["small-letters"]}
            </p>
             <div className="flex" style={{alignItems: "center"}}>
             <h5 className={props.render !== "loop"? "flex " : "foterText"}>שתפו את הבום עם חברים</h5>
            {/* <h5>
              {!props.productData["views"] || props.productData["views"] === "0"
                ? "תהיו הראשונים לצפות בקופון"
                : "צפו במוצר:"}{" "}
              {props.productData["views"] === "0"
                ? ""
                : props.productData["views"] < 1000
                ? props.productData["views"]
                : Math.round((props.productData["views"] / 1000) * 10) / 10 +
                  "k"}
            </h5> */}
            <div className="icons">
              <a
                target="blank"
                href={
                  "https://t.me/share/url?url=" +
                  currentUrl +
                  "&text=" +
                  shareText
                }
              >
                <img src={s_telegram} />
              </a>
              <a target="blank" href={"whatsapp://send?text=" + shareText}>
                <img src={s_whatsapp} />
              </a>
              <a
                target="blank"
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl
                }
              >
                <img src={s_facebook} />
              </a>
            </div>
            </div>
          </div> </div>
  );
}

export default Product;
