//react hooks
import React, { useState, useEffect } from "react";
import Favicon from "react-favicon";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Loop from "./Loop.js";
import Banner from "./Banner.js";
import main from "./assets/main.png";
import ProdactCategoryn from "./ProdactCategoryn.js";
import img_a from "./product-imgs/product_a.svg";
import arrow_right from "./assets/arrow-right.svg";
import arrow_left from "./assets/arrow-left.svg";
import OffProduct from "./OffProduct.js";


//component
function Category(props) {
const [products, setProducts] = useState([{}]);
  const [options, setOptions] = useState([{}]);
  const [loading, setLoad] = useState(0);
    const [grab, setGrab] = useState(0);
  const [grads, setGrads] = useState({});
  const [reminderUser, setReminderUser] = useState(0);
  const [userProdact, setUserProdact] = useState(0);
const currentUrl = window.location.hostname + "/" + products["id"];
const shareText = "הוזמנת לצפות בקופון מטורף, כנסו עכשיו!!" + currentUrl;
// useEffect(async () => {
//       // const _id =props.options.categoryid;
//       const _id =props.id;

//       async function fetchMyAPI() {
//         try {
//             let response = await fetch(`https://manage.boombuy.co.il/wp-json/react/v1/products/category/${_id}`,
//         {
//           method: "GET",
//         })
//          .then(async(response) => await response.json())
//       .then((resData) => {
//         setProducts(resData);
//         setTimeout(function () {
//           setLoad(1);
//         }, 250);
//       });
//         } catch (e) {
//             console.error(e);
//         }
       

//       }
//       fetchMyAPI()
//   }, [products]);
  let max_h = props.width > 900 ? "55vh" : "20px";
    return (
    <div className="categoris App">
    {/* <Header
        // width={width}
        showBooten={options.bottennight}
        name_catgory={options.name_catgory}
        showMesseg={options.messegcategory}
        goToRegister={() => {
          loadPage();
          setPrevPage(page);
          setPage("sign_in");
          window.history.pushState({}, "", "/sign_in");
        }}
        toggleHamburger={() => setHamburger(hamburger * -1)}
        settings={options}
        userData={user}
        page={page}
        removeUser={() => {
          setUser({});
          document.cookie = "user_id=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          loadPage();
        }}
      /> */}
        <div className="bomim-categoris padding2">

         <div className="column" style={{flex: "0 0 45%", justifyContent: "center",display: "flex", paddingRight: "75px"}} >
            <div className="row bomim-categoris-titel">
                <div className="bomim-categoris-titel bold" style={{paddingLeft: "11px"}}>{props.titelSpecile}</div><div className="bomim-categoris-titel">בומים שלא כדאי לפספס</div>
            </div>
            <div className="bomim-categoris-text"> {props.textSpecile} </div>
         </div>
         <div style={{flex: "0 0 45%"}}>
         <img style={{justifyContent: "center", width: "20%"}}  src={`${props.image}`}/>
         </div>
        
        </div>
        <div className="padding2 " style={{display: "flex", flexWrap: 'wrap', justifyContent:'center'}}>
        {products.length > 0 ? (
           (products.map((single, index) =>{
           console.log(products[index]["id"],"products[index]products[index]");
              {return products[index]["id"] &&(
              <ProdactCategoryn
                  key={index}
                  userData={props.user}
                  time={props.time}
                  render={"category"}
                  productData={products[index]}
                  changePage = {() => {props.changePage(index)}}
                  changePage_andMain={() => {
                    // console.log(props.data[index],"props.data-----[index]");

                    props.changeMain(index);
                    props.changePage(products[index]["id"]);
                  
                  }}
              />  )}
              
                }
              ))
            ) : (
              <p>No posts</p>
            )}    

        </div>

        <div className="padding2"> 

        </div>
        <div>
        <Footer
        // changePage={(i) => {
        //   setPrevPage(page);
        //   setPage(i);
        //   window.history.pushState({}, "", "/" + i);
        //   loadPage();
        // }}
      /></div>
     
    </div>
  );


}

export default Category;
